import React from 'react'
import Layout from "../../components/layout"
const OMG = () => {
    return (
        <Layout>
            <p>aaaaaaaaaa</p>
        </Layout>
    )
}

export default OMG